/* Start Blog Posts Page Styles */
.blog-posts-wrapper {
	padding-bottom: 0; }
/* End Blog Posts Page Styles */

/* Start Single Post Styles */
.blog-post-wrapper {
	.blog-post-content {
		p {
			margin-bottom: 20px; } }
	.social-list {
		.icon {
			@include circle_with_border(40px, 1px, $heading_color); } }
	.tag-share-wrapper {
		padding-top: 60px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		.tags {
			h4 {
				margin-bottom: 0; }
			a {
				margin-left: 5px;
				color: $faded;
				text-transform: capitalize;
				@include transition(.3s, ease);
				&:hover {
					color: $heading_color; } } } } }
/* End Single Post Styles */
