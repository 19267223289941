.widget {
	margin-bottom: 40px;
	&:last-of-type {
		margin-bottom: 70px; }
	.list-unstyled {
		margin-bottom: 0; }
	.search-form {
		.form-group {
			position: relative;
			margin-bottom: 0;
			.search-btn {
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				background-color: transparent;
				color: $faded;
				@include transition(.3s, ease);
				&:hover {
					color: $heading_color; } } } }
	li {
		margin-bottom: 10px;
		&:last-of-type {
			margin-bottom: 0; }
		a {
			display: block;
			color: $faded;
			font-family: $main_font;
			font-weight: 700;
			@include transition(.3s, ease);
			&:hover {
				color: $heading_color; } } }
	.popular-posts {
		.widget-post-img {
			opacity: 1;
			@include transition(.3s, ease);
			&:hover {
				opacity: .8; } }
		img {
			max-width: 90px;
			height: auto; }
		.widget-post-content {
			padding-top: 10px;
			margin-left: 110px;
			a {
				color: $heading_color;
				margin-bottom: 10px;
				&:hover {
					color: transparentize($heading_color, .2); } } } }
	.tags {
		li {
			display: inline-block;
			margin-right: 10px;
			@include transition(.3s, ease);
			a {
				border: 1px solid $faded;
				padding: 6px 16px; }
			&:hover {
				background-color: $heading_color;
				a {
					border-color: $heading_color;
					color: #fff; } } } } }
.blog-post-wrapper {
	.widget:last-of-type {
		margin-bottom: 30px; } }
