.breadcrumb {
	background-color: transparent;
	padding: 0;
	margin: 0;
	.breadcrumb-item {
		text-transform: uppercase;
		a {
			color: #fff;
			position: relative;
			&::after {
				position: absolute;
				content: '';
				width: 0px;
				height: 2px;
				background-color: #FFF;
				left: 0;
				bottom: -10px;
				@include transition(.3s, ease); }
			&:hover::after {
				width: 30px; } }
		&.active {
			color: $p_light_color;
			&::before {
				@extend %active-li-before; } } }
	>li+li::before {
		@extend %active-li-before; } }


%active-li-before {
	color: #fff;
	content: '\f178';
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }
