/*---------------------------------------------------------------
  24 - Hero Area */
/*--------------------------------------------------------------*/
@import "hero";

/*---------------------------------------------------------------
  25 - Page Title Area */
/*--------------------------------------------------------------*/
@import "page-title";

/*---------------------------------------------------------------
  26 - Footer */
/*--------------------------------------------------------------*/
@import "footer";
