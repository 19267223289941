.blog-post,
.blog-post-content {
	.post-img {
		img {
			@include transition(.3s, ease); }
		&:hover {
			img {
				opacity: .8; } } }
	.post-meta {
		margin-bottom: 20px;
		margin-top: 20px;
		a {
			color: $faded;
			margin-right: 10px;
			font-size: 14px;
			@include transition(.3s, ease);
			&:hover {
				color: $p_sec_color; } }
		i {
			margin-right: 5px; } }
	.post-title {
		margin-bottom: 30px;
		color: $heading_color;
		text-transform: capitalize;
		a {
			color: $heading_color;
			@include transition(.3s, ease); }
		&:hover {
			a {
				color: transparentize($heading_color, .2); } } }

	.post-desc {
		p {
			margin-bottom: 30px; }
		.read-more {
			i {
				margin-left: 5px;
				@include transition(.3s, ease); }
			&:hover {
				i {
					margin-left: 10px; } } } } }
.blog-post.list {
	.post-title {
		margin-bottom: 20px;
		@media only screen and (max-width: 991px) {
			margin-top: 40px; } } }
