#clients {
	padding: 80px 0;
	background-color: $light_bg;
	.owl-carousel {
		cursor: move; }
	.client {
		padding: 10px 20px;
		opacity: 0.5;
		@include transition(0.3s, ease);
		img {
			max-width: 100%; }
		&:hover {
			opacity: 1; } } }
