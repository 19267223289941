// global styles like bootstrap modal resets
// global styles like global classes
* {
	outline: none; }

body {
	font-family: $sec_font;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.65;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizelegibility; }

::-moz-selection {
	text-shadow:none {} }

::selection {
	background-color: #333;
	color: #fff;
	text-shadow: none; }

a,
a:focus,
a:hover {
	outline: none;
	color: $heading_color;
	text-decoration: none; }

.section {
	padding-top: 80px;
	padding-bottom: 40px; }

.has-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center; }

.jarallax {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%; }

.overlay-dark,
.overlay-white {
	position: relative;
	&::before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0; } }
.overlay-dark {
	&::before {
		background-color: $overlay_dark_bg; } }
.overlay-white {
	&::before {
		background-color: $overlay_white_bg; } }
.light-bg {
	background-color: $light_bg; }
.dark-bg {
	background-color: $dark_bg; }

.no-gutter {
	padding: 0; }

.mt-10 {
	margin-top: 10px; }

.mr-20 {
	margin-right: 20px; }

.ml-20 {
	margin-left: 20px; }

.mt-20 {
	margin-top: 20px; }

.mb-20 {
	margin-bottom: 20px; }

.mt-30 {
	margin-top: 30px; }

.mb-30 {
	margin-bottom: 30px; }

.mt-40 {
	margin-top: 40px; }

.mb-40 {
	margin-bottom: 40px; }

.ml-40 {
	margin-left: 40px; }

.mb-80 {
	margin-bottom: 80px; }

.big-num {
	position: absolute;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 165px;
	font-family: $main_font;
	font-weight: 700;
	color: #fff;
	@media only screen and(max-width: 991px) {
		display: none; } }
.mid-num {
	font-size: 45px;
	color: $p_sec_color; }
.count {
	font-size: 45px; }
