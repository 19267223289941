footer {
	background-color: $footer_bg;
	padding-top: 80px;

	.logo {
		img {
			max-width: 80px;
			height: auto;
			margin-bottom: 20px; } }

	.follow {
		padding-bottom: 75px;
		h3 {
			margin-bottom: 40px;
			color: $heading_light_color; } }

	.copyright {
		background-color: $copyright_bg;
		padding-top: 30px;
		padding-bottom: 20px;
		p {
			color: $p_light_color; }
		.heart {
			color: $heart_color;
			animation: heartBeat 2s infinite; } } }

@-webkit-keyframes heartBeat {
	0%, 50%, 100% {
		transform: scale(1); }
	5% {
		transform: scale(1.1); }
	10% {
		transform: scale(1.2); }
	15% {
		transform: scale(1.3); } }

