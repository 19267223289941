.btn,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn.active.focus {
	outline: none;
	box-shadow: none; }

.btn {
	border-radius: 0;
	padding: 0;
	text-transform: uppercase;
	font-family: $main_font;
	font-weight: 700;
	@include transition(.3s, ease);
	&.btn-block {
		width: 100%;
		display: block; } }

.primary-btn {
	@include box_with_border(160px, 45px, 2px, $heading_color);
	background-color: transparent;
	color: $heading_color;
	&:hover {
		background-color: $heading_color;
		color: #fff; }
	&.white {
		border-color: #fff;
		color: #fff;
		&:hover {
			border-color: transparent; } } }

.secondary-btn {
	@include box_with_border(160px, 45px, 2px, transparent);
	background-color: $heading_color;
	color: #fff;
	&:hover {
		background-color: transparent;
		border-color: $heading_color; }
	&:focus,
	&:active {
		color: #fff;
		background-color: $heading_color; }
	&.lighten {
		&:hover {
			background-color: lighten($heading_color, 5%);
			color: #fff;
			border-color: transparent; } }
	&.white {
		background-color: #fff;
		color: $heading_color;
		&:hover {
			background-color: $heading_color;
			color: #fff;
			border-color: transparent; } } }
.read-more {
	position: relative;
	color: $heading_color;
	background-color: transparent;
	padding-bottom: 10px;
	&::after {
		position: absolute;
		content: '';
		bottom: 0;
		left: 0;
		width: 0;
		height: 2px;
		background-color: $heading_color;
		@include transition(.3s, ease); }
	&:hover {
		&::after {
			width: 100%; } } }

.btn-small {
	@include box_with_border(95px, 35px, 2px, $heading_color); }
