// Bachgrounds variables
$footer_bg: #1a1a1a;
$copyright_bg: #181818;
$dark_bg: #333;
$light_bg: #f6f6f6;
$overlay_dark_bg: transparentize(#252525, .2);
$overlay_white_bg: transparentize(#fff, .5);

// Colors variables
$faded: #aaa;
$heading_color: #333;
$heading_light_color: #fff;
$p_color: #777;
$p_sec_color: #999;
// $p_ultra_sec_color: #999
$p_light_color: #ccc;
$heart_color: #ec2f2f;

// Brand Colors
$fb_color: #3b5998;		// facebbok
$tw_color: #1da1f2;		// Twitter
$lin_color: #0077b5;		// Linkedin
$gh_color: #333;				// Github
$be_color: #1769ff;		// Behance
$gp_color: #dd4b39;		// Google-Plus
$drib_color: #ea4c89;		// Dribbble
$pin_color: #bd081c;		// Pinterest

// Fonts variabls
$main_font: 'Montserrat', sans-serif;
$sec_font: 'Open Sans', sans-serif;
