/* Start About Section */
.about-page {
	.about {
		.section-title {
			@media (max-width: 991px) {
				text-align: center; } }
		.profile-wrapper img {
			margin-left: auto;
			margin-right: auto; }
		.profile-info {
			span {
				font-weight: 600;
				color: $heading_color;
				margin-right: 10px; }
			a {
				color: $p_color;
				@include transition(.3s, ease);
				&:hover {
					color: $heading_color; } } }
		.more-info {
			.btn {
				margin-top: 20px;
				&+.btn {
					margin-left: 20px; } } } } }

.about-us {
	h2 {
		line-height: 1.5;
		margin-bottom: 40px; } }
/* End About Section */
