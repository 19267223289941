.blog-pagination {
	line-height: 0;
	margin-bottom: 80px;
	.pagination {
		margin: 0;
		>li {
			display: inline-block;
			background-color: transparent;
			margin-left: 5px;
			margin-right: 5px;
			&:first-of-type {
				margin-left: 0; }
			>a {
				padding: 0;
				color: $heading_color;
				font-weight: 700;
				font-family: $main_font;
				font-size: 18px;
				@include box_with_border(40px, 40px, 1px, $dark_bg);
				@include transition(.3s, ease); }
			&:hover {
				>a {
					border-color: $dark_bg;
					background-color: $dark_bg;
					color: #fff; } }
			&:first-child,
			&:last-child {
				>a {
					border-radius: 0; } } }
		>.active {
			>a, >a:hover {
				color: #fff;
				background-color: $dark_bg;
				border-color: $dark_bg; } } } }
