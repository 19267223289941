.portfolio-item {
	position: relative;
	overflow: hidden;
	margin-bottom: 40px;
	.portfolio-img {
		@include transition(.3s, ease); }
	.portfolio-caption {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 20px;
		background-color: transparentize(#000, .1);
		opacity: 0;
		visibility: hidden;
		@include transition(.3s, ease);
		.text {
			position: absolute;
			text-align: center;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
			h4, p {
				opacity: 0;
				visibility: hidden;
				@include transition(.3s, ease); }
			h4 {
				margin-bottom: 10px;
				transform: translateY(15px); }
			p {
				margin-bottom: 0;
				transform: translateY(-15px); } } }
	&:hover {
		.portfolio-img {
			transform: rotate(5deg) scale(1.15); }
		.portfolio-caption {
			opacity: 1;
			visibility: visible;
			.text {
				h4, p {
					opacity: 1;
					visibility: visible;
					transform: translateY(0); }
				p {
					transition-delay: .15s; } } } } }
