.our-team {
	.member {
		max-width: 400px;
		margin: 0 auto 40px;
		.member-info,
		.member-social {
			margin-top: 20px; }
		.member-info {
			h4 {
				margin-bottom: 10px; }
			p {
				margin-bottom: 0;
				color: $faded;
				font-style: italic; } } } }
