/*---------------------------------------------------------------
  27 - Home Page Styles */
/*--------------------------------------------------------------*/
@import "homepages";

/*---------------------------------------------------------------
  28 - About Page Styles */
/*--------------------------------------------------------------*/
@import "about";

/*---------------------------------------------------------------
  29 - Single Project Page Styles */
/*--------------------------------------------------------------*/
@import "single-project";

/*---------------------------------------------------------------
  30 - Blog Pages Styles */
/*--------------------------------------------------------------*/
@import "blog-pages";

/*---------------------------------------------------------------
  31 - Contact Pages Styles */
/*--------------------------------------------------------------*/
@import "contactpage";
