/*---------------------------------------------------------------
	Template Name: Xprieme
	Description: Responsive HTML5 / CSS3 Multi-pages Portfolio-Resume Template
	Version: 1.0
	Author: Ahmed Beheiry
	URL: https://themeforest.net/user/ahmedbeheiry
	--------------------------------------------------------------

	---------------------------------------------------------------
		*** Table Of Content:
	---------------------------------------------------------------
	1 - Reset
	2 - Typography
	3 - Preloader Page
	4 - Buttons
	5 - NavBar
	6 - IconBox
	7 - Breadcrumb
	8 - CTA
	9 - Portfolio
	10 - Team Members
	11 - Testimonials
	12 - Timeline
	13 - Skills
	14 - Pricing-tables
	15 - Blog-Post
	16 - Pagination
	17 - Comment-Box
	18 - Social-Links
	19 - Blockquote
	20 - Contact Form
	21 - To Top Button
	22 - Hero Area
	23 - Page Title Area
	24 - Footer
	25 - Home Page Styles
	26 - About Page Styles
	27 - Single Project Page Styles
	28 - Blog Pages Styles
	29 - Contact Pages Styles */
/*--------------------------------------------------------------*/
// Helpers
@import "helpers/helpers";

/*---------------------------------------------------------------
	1 - Reset */
/*--------------------------------------------------------------*/
@import "base/global";

/*---------------------------------------------------------------
	2 - Typography */
/*--------------------------------------------------------------*/
@import "base/typography";

// components
@import "components/components";

// Layout
@import "layout/layout";

// Pages
@import "pages/pages";
