.timeline {
	margin-top: 40px;
	margin-bottom: 40px;
	.duration {
		padding: 0;
		text-align: right; }
	h3 {
		text-transform: capitalize;
		margin-bottom: 10px; }
	.sub-title {
		margin-bottom: 20px;
		font-style: italic;
		color: $faded; }
	.details {
		position: relative;
		padding-left: 65px;
		padding-bottom: 40px;
		&::before {
			position: absolute;
			content: '';
			width: 3px;
			height: 100%;
			background-color: $dark_bg;
			left: 24px;
			top: 0; }
		i {
			@include circle_with_border(40px, 2px, $heading_color);
			left: 5px;
			top: -10px;
			position: absolute;
			background-color: #FFF;
			color: $heading_color;
			font-size: 21px; } }
	@media only screen and (max-width: 767px) {
		.duration {
			text-align: left;
			padding-left: 15px;
			padding-right: 15px;
			margin-bottom: 20px; }
		.details {
			padding-left: 60px;
			padding-bottom: 0;
			margin-bottom: 40px;
			&::before {
				left: 19px; }
			i {
				left: 0; } } } }
