.comment-box {
	padding: 20px;
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
	margin-bottom: 40px;
	.media-body {
		h3 {
			margin-bottom: 10px;
			a {
				@include transition(.3s, ease); }
			&:hover {
				a {
					color: transparentize($heading_color, .2); } } } }
	.comment-text {
		margin-bottom: 20px;
		margin-top: 20px; }
	&.is-reply {
		margin-left: 40px; }
	&:last-child {
		margin-bottom: 80px; } }
