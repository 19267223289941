.pricing-table {
	background-color: #FFF;
	text-align: center;
	padding: 40px 50px;
	margin-bottom: 40px;
	@include transition(.3s, ease);
	.table-price {
		p {
			color: $faded; } }
	hr {
		margin-top: 40px;
		margin-bottom: 40px;
		border-color: $light_bg; }
	.table-features {
		ul {
			margin-bottom: 40px;
			li {
				margin-bottom: 20px;
				color: $p_color; } } }
	&:hover {
		box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15); } }
