@mixin transition($s, $fun) {
	transition: all $s $fun; }

@mixin circle_with_border($s, $bs, $bc) {
	width: $s;
	height: $s;
	border: $bs solid $bc;
	line-height: $s - 2*$bs;
	border-radius: 50%;
	text-align: center; }

@mixin box_with_border($w, $h, $bs, $bc) {
	width: $w;
	height: $h;
	border: $bs solid $bc;
	line-height: $h - 2*$bs;
	text-align: center; }

@mixin box_no_border($w, $h) {
	width: $w;
	height: $h;
	line-height: $h;
	text-align: center; }
