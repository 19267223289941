.header-1 {
	&.transparent-header {
		.navbar {
			background-color: transparent;
			border: none;
			@include transition(.5s, ease);
			&.affix-top {
				.open {
					.dropdown-toggle {
						color: #fff; } }
				.dark-logo {
					display: none; } }
			&.affix {
				background-color: #fff;
				box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
				.white-logo {
					display: none; } } } }
	.navbar-nav {
		>li {
			>a {
				position: relative;
				background-color: transparent !important;
				font-family: $main_font;
				font-weight: 700;
				padding: 0 15px;
				line-height: 80px;
				@include transition(.3s, ease);
				color: $p_light_color;
				&::after {
					position: absolute;
					content: '';
					width: 0px;
					height: 2px;
					background-color: #fff;
					left: 15px;
					bottom: 15px;
					@include transition(.3s, ease); } }
			&:hover {
				>a {
					background-color: transparent;
					&::after {
						width: 30px; }
					&:focus {
						background-color: transparent; } } }
			&.active {
				>a {
					color: #fff;
					&:focus {
						background-color: transparent;
						color: #fff; }
					&::after {
						width: 30px; } }
				&:hover {
					>a {
						color: #fff; } } }
			&:hover {
				>a {
					color: #fff; } }
			&.open {
				.dropdown-toggle {
					color: $heading_color; } } }
		.dropdown-menu {
			background-clip: border-box;
			background-color: #FFF;
			right: 15px;
			border-radius: 0;
			border: none;
			border-top: 2px solid $dark_bg;
			padding: 0;
			>li {
				>a {
					font-family: $main_font;
					font-weight: 700;
					padding: 15px 20px;
					@include transition(.3s, ease);
					&:hover {
						background-color: $dark_bg;
						color: #fff; } } } } }

	.affix {
		.navbar-nav {
			>li {
				>a {
					color: $faded;
					&::after {
						background-color: $heading_color; } }
				&.active,
				&:hover {
					>a {
						color: $heading_color; } } } } }
	.navbar-header {
		height: 80px;
		.navbar-toggle {
			background-color: $heading_color;
			padding: 10px;
			border: none;
			border-radius: 0;
			margin-top: 23px;
			margin-bottom: 23px;
			.icon-bar {
				background-color: #FFF;
				width: 25px;
				transform-origin: 50% 50%;
				@include transition(0.3s, ease);
				&:nth-child(3) {
					opacity: 0; }
				&:nth-child(2) {
					transform: rotate(-45deg) translate(-4px, 5px); }
				&:nth-child(4) {
					transform: rotate(45deg) translate(-4px, -4px); } }
			&.collapsed {
				.icon-bar {
					&:nth-child(3) {
						opacity: 1; }
					&:nth-child(2),
					&:nth-child(4) {
						transform: rotate(0deg) translate(0px); } } } } }
	.navbar-brand {
		padding-top: 15px;
		img {
			height: 50px;
			width: auto; } } }

.homepage-3 {
	.header-1 .navbar-nav > li.active > a,
	.header-1 .navbar-nav > li:hover > a {
		color: $heading_color;
		&::after {
			background-color: $heading_color; } }
	.header-1.transparent-header .navbar.affix-top .open .dropdown-toggle {
		color: $heading_color; } }

@media only screen and (max-width: 767px) {
	.navbar-collapse {
		background-color: transparentize($heading_color, .15);
		li {
			padding-left: 15px; } }
	.affix {
		.navbar-collapse {
			background-color: #fff; } } }
