.skill {
	margin-bottom: 40px;
	h3 {
		margin-bottom: 10px; } }
.progress {
	margin: 0;
	padding-top: 5px;
	overflow: visible;
	background-color: $light_bg;
	border-radius: 0 10px 10px 0;
	.progress-bar {
		height: 10px;
		border-radius: 0 5px 5px 0;
		background-color: $dark_bg;
		position: relative;
		.percent {
			position: absolute;
			display: block;
			@include box_no_border(30px, 30px);
			background-color: $dark_bg;
			border-radius: 5px;
			top: -50px;
			right: -15px;
			&::after {
				position: absolute;
				content: '';
				top: 30px;
				left: 10px;
				border-top: 5px solid $dark_bg;
				border-right: 5px solid transparent;
				border-left: 5px solid transparent; } } } }
.skill.has-animation {
	.percent {
		opacity: 0; } }
