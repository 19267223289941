.contact-form,
.search-form {
	.form-group {
		margin-bottom: 40px;
		label {
			display: block;
			font-weight: 400;
			color: $faded; }
		.asterisk {
			color: red; }
		input,
		textarea {
			border: none;
			border-radius: 0;
			box-shadow: none;
			border-bottom: 1px solid #ccc;
			padding-left: 0;
			@include transition(.3s, ease);
			&:focus {
				border-color: #333;
				box-shadow: none; } }
		textarea {
			min-height: 80px;
			resize: none; } }
	.form-response {
		margin-top: 20px;
		margin-bottom: 0;
		i {
			font-size: 24px;
			margin-right: 5px;
			&.fa-times {
				color: #ff3e3e; }
			&.fa-check {
				color: #4ac94f; } } } }
