.social-list {
	line-height: 1;
	.icon {
		display: inline-block;
		@include circle_with_border(50px, 1px, #fff);
		margin: 5px;
		font-size: 20px;
		color: #fff;
		overflow: hidden;
		@include transition(.3s, ease);
		&.fb:hover {
			background-color: $fb_color; }
		&.tw:hover {
			background-color: $tw_color; }
		&.gh:hover {
			background-color: $gh_color; }
		&.lin:hover {
			background-color: $lin_color; }
		&.be:hover {
			background-color: $be_color; }
		&.gp:hover {
			background-color: $gp_color; }
		&.pin:hover {
			background-color: $pin_color; }
		&.drib:hover {
			background-color: $drib_color; }
		&:hover {
			border-color: transparent;
			i {
				line-height: inherit;
				color: #fff;
				animation: icontotop .3s forwards ease;
				@include transition(.3s, ease); } } }
	&.style-2 {
		.icon {
			@include circle_with_border(35px, 1px, $heading_color);
			color: $heading_color;
			font-size: 18px;
			&:hover {
				border-color: transparent; } } }
	&.member-social {
		.icon {
			color: $heading_color;
			margin: 0; } } }

@keyframes icontotop {
	49% {
		transform: translateY(-100%); }
	50% {
		transform: translateY(100%); } }
