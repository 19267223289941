// Variables
$animTime: 2s;
$animColor: #333;
.preloader {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 9999;
	.pulse {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -25px;
		margin-left: -25px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		opacity: .35;
		background-color: $animColor;
		animation: pulsing $animTime infinite ease-in-out;
		&:nth-of-type(2) {
			animation-delay: -1s; } } }

@keyframes pulsing {
	0%, 100% {
		transform: scale(0); }
	50% {
		transform: scale(1); } }
