.full-size-hero {
	height: 100vh; }
.fixed-height-hero {
	height: 700px; }
.hero-content-wrapper {
	width: 100%;
	height: 100%;
	display: table;
	.hero-content {
		display: table-cell;
		vertical-align: middle;
		h1 {
			font-size: 60px;
			@media only screen and (max-width: 767px) {
				font-size: 40px; } }
		p {
			letter-spacing: 1px; }
		.primary-btn {
			&:hover {
				background-color: #FFF;
				color: $heading_color; } } } }
.fixed-height-hero {
	.hero-content {
		.lead {
			text-transform: uppercase; } } }
.overlay-white .hero-content .primary-btn {
	&:hover {
		background-color: $heading_color;
		color: #fff; } }
