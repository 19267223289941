.icon-box-wrapper {
	margin-bottom: 40px;
	.icon-box {
		@include circle_with_border(80px, 1px, $heading_color);
		font-size: 35px;
		text-align: center;
		margin: 0 auto 20px;
		background-color: transparent;
		overflow: hidden;
		@include transition(.3s, ease);
		i {
			display: block;
			position: relative;
			line-height: inherit;
			@include transition(.3s, ease); }
		&.icon-dark {
			background-color: $heading_color;
			color: #fff; } }
	&:hover {
		.icon-box {
			background-color: $heading_color;
			i {
				color: #fff; }
			i:first-child {
				margin-top: -78px; } } }  // box height - 2*line-height

	.icon-box-2 {
		@include box_no_border(80px, 80px);
		font-size: 35px;
		margin-bottom: 20px;
		overflow: hidden;
		@include transition(.3s, ease);
		&.icon-dark {
			background-color: $heading_color;
			color: #fff; }
		i {
			display: block;
			position: relative;
			line-height: inherit;
			@include transition(.3s, ease); } }
	&:hover {
		.icon-box-2 {
			background-color: $heading_color;
			i {
				color: #fff; }
			i:first-child {
				margin-top: -80px; } } } }  // box height
.dark-bg .icon-box-wrapper {
	.icon-box {
		border-color: #fff;
		color: #fff; }
	&:hover {
		.icon-box {
			background-color: #FFF;
			i {
				color: $heading_color; } } } }
