.testimonials {
	.test-carousel {
		cursor: move; }
	.testimonial-content {
		background-color: $light_bg;
		padding: 20px;
		position: relative;
		i {
			float: left;
			font-size: 24px; }
		p {
			padding-left: 40px; }
		&::after {
			position: absolute;
			content: '';
			bottom: -10px;
			left: 80px;
			border-top: 10px solid $light_bg;
			border-right: 10px solid transparent;
			border-left: 10px solid transparent; } }
	.testimonial-meta {
		img {
			box-shadow: 0px 0px 15px 0 rgba(0,0,0,.15);
			width: 100px;
			height: 100px; }
		.author {
			padding-top: 20px;
			h4 {
				margin-bottom: 10px; } } }
	.autor-details {		// with flex box
		display: inline-flex;
		justify-content: cneter;
		align-items: center;
		.author-profile {
			img {
				box-shadow: 0px 0px 15px 0 rgba(0,0,0,.15);
				width: 100px;
				height: 100px; } }
		.author-title {
			margin-left: 20px;
			h4 {
				margin-bottom: 10px; }
			p {
				margin-bottom: 0px; } } }
	.owl-dots {
		text-align: center;
		margin-bottom: 40px;
		.owl-dot {
			display: inline-block;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			background-color: transparentize($dark_bg, .5);
			@include transition(.3s, ease);
			&+.owl-dot {
				margin-left: 5px; }
			&.active,
			&:hover {
				background-color: $dark_bg; } } }
	&.light-bg {
		.testimonial-content {
			background-color: #fff; } } }
