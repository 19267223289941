.single-project {
	.project-details {
		margin-bottom: 0;
		li {
			margin-bottom: 20px; }
		h5 {
			display: inline-block;
			min-width: 140px;
			padding-right: 10px;
			font-size: 16px;
			margin-bottom: 0; }
		span {
			color: $p_color; }
		a {
			color: $p_color;
			border-bottom: 1px solid $p_color;
			@include transition(.3s, ease);
			&:hover {
				color: $heading_color;
				border-color: $heading_color; } } }
	.project-nav {
		margin: 0;
		li {
			display: inline-block;
			position: relative;
			&.prev {
				float: left;
				i {
					margin-right: 10px; } }
			&.next {
				float: right;
				i {
					margin-left: 10px; } }
			&::after {
				position: absolute;
				content: '';
				width: 0;
				height: 2px;
				background-color: $dark_bg;
				bottom: -10px;
				@include transition(.3s, ease); }
			&.prev {
				&::after {
					right: 0; }
				&:hover {
					&::after {
						width: 100%; } } }
			&.next {
				&::after {
					left: 0; }
				&:hover {
					&::after {
						width: 100%; } } } } }
	.related-projects {
		padding-top: 40px;
		padding-bottom: 40px; } }
