/*----------------------------------------------
  homepage-1 Styles */
/*---------------------------------------------*/
/* Start About Me Section */
.about-me {
	padding-bottom: 0;
	@media only screen and(max-width: 991px) {
		padding-bottom: 40px; }
	.profile-wrapper {
		img {
			margin-left: auto;
			margin-right: auto; }
		@media only screen and(max-width: 991px) {
			margin-bottom: 40px; } }
	h2 {
		line-height: 1.5;
		margin-bottom: 40px; }
	.btn {
		margin-top: 30px;
		margin-bottom: 40px; } }
/* End About Me Section */

/* Start Work Process Section */
.homepage-1 {
	.work-process {
		.process {
			position: relative;
			z-index: 1; }
		p {
			margin: 0; } } }
/* End Work Process Section */

/*----------------------------------------------
  homepage-3 Styles */
/*---------------------------------------------*/
/* Start About-Intro Section */
.homepage-3 {
	.about-intro {
		h2 {
			line-height: 1.6;
			margin-bottom: 40px; } } }
/* End About-Intro Section */
