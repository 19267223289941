#to-top {
	position: fixed;
	z-index: 3000;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
	opacity: 0;
	@include transition(.3s, ease);

	&.totop {
		@include box_no_border(40px, 40px);
		color: #fff;
		background-color: transparentize($heading_color, .65);
		&.style-2 {
			border-radius: 50%; }
		&.style-3 {
			border-radius: 5px; } }
	&.show {
		opacity: 1; }
	&:hover {
		background-color: $heading_color; } }

@media only screen and (max-width : 767px) {
	#to-top.show {
		opacity: 0; } }
