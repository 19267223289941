h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-family: $main_font;
	font-weight: 700;
	color: $heading_color;
	margin-top: 0;
	margin-bottom: 20px;
	text-transform: capitalize;
	&.light {
		color: $heading_light_color; } }
p {
	color: $p_color;
	&.sec {
		color: $p_sec_color; }
	&.light {
		color: $p_light_color; } }
.italic {
	font-style: italic; }
.capitalize {
	text-transform: capitalize; }
