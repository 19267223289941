/* Start Contact-1 Page */
.contact-1 {
	.contact-details {
		padding-top: 40px; }
	#map {
		height: 600px; } }
/* End Contact-1 Page */

/* Start Contact-2 Page */
.contact-2 {
	#map {
		height: 400px; }
	.contact-details {
		display: table;
		width: 100%;
		padding-left: 80px;
		padding-right: 80px;
		min-height: 400px;
		.intro {
			display: table-cell;
			vertical-align: middle;
			i {
				margin-right: 10px; } } }
	#contact-form {
		margin-bottom: 40px; } }

/* End Contact-2 Page */
